<template>
  <div class="add-delivery-plan p-4">
    <div class="row m-0">
      <div class="col-6">
        <span class="font-weight-bold" style="font-size: 20px">配送計画を追加する</span>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button class="close-btn" @click="closeRegister"><b-icon-x /></button>
      </div>
    </div>
    <form @submit.prevent="registDelivery">
      <div class="row m-0 mt-2 regist-body">
        <div class="row m-0 w-100 mb-4">
          <div class="col-4">
            <p class="required">配送番号</p>
          </div>
          <div class="col-6">
            <input class="w-100 info-input" required type="number" v-model="deliveryNumber" />
          </div>
        </div>
        <div class="row m-0 w-100 mb-4">
          <div class="col-4">
            <p>担当者</p>
          </div>
          <div class="col-6">
            <select class="dropdown-data" v-model="inChargePerson">
              <option v-for="(option, idx) in inChargePersonList" :key="idx" :value="option.key">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="row m-0 w-100 mb-4">
          <div class="col-4">
            <p>配送日</p>
          </div>
          <div class="col-6">
            <div class="info-input input-group d-flex align-items-center">
              <date-picker
                class="w-100 border-0"
                v-model="deliveryDate"
                format="yyyy/M/d"
                ref="deliveryDate"
              ></date-picker>
              <b-icon-calendar4 style="position: absolute; right: 0" @click="selectDeliveryDate" />
            </div>
          </div>
        </div>
        <div class="row m-0 w-100 mb-4">
          <div class="col-4">
            <p class="required">エリア</p>
          </div>
          <div class="col-6">
            <select class="dropdown-data" required v-model="area">
              <option v-for="(option, idx) in areaList" :key="idx" :value="option.key">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="row m-0 w-100 mb-4">
          <div class="col-4">
            <p class="required">配送地点名</p>
          </div>
          <div class="col-6">
            <input class="w-100 info-input" required v-model="deliveryPointName" />
          </div>
        </div>
        <div class="row m-0 w-100 mb-4">
          <div class="col-4">
            <p class="required">顧客電話番号</p>
          </div>
          <div class="col-6">
            <input
              class="w-100 info-input"
              placeholder="XXX-XXXX-XXX"
              @keypress="filterPhoneNumberCharacter($event)"
              maxlength="13"
              required
              v-model="customerPhoneNumber"
            />
          </div>
        </div>
        <div class="row m-0 w-100 mb-4">
          <div class="col-4">
            <p class="required">配送先住所</p>
          </div>
          <div class="col-6">
            <input class="w-100 info-input" required v-model="deliveryAddress" />
          </div>
        </div>
        <div class="row m-0 w-100 mb-4">
          <div class="col">
            <p>予定時刻</p>
            <div class="row m-0">
              <div class="col-8 pl-0">
                <table class="w-100 schedule-time-tbl">
                  <tbody>
                    <tr>
                      <td class="table-col">到着</td>
                      <td class="table-col">
                        <input
                          @keypress="filterCharacter($event)"
                          maxlength="5"
                          class="schedule-time-input"
                          v-model="scheduleArrival"
                          @change="calculateWorkingTime"
                        />
                      </td>
                      <td class="table-col">出発</td>
                      <td class="table-col">
                        <input
                          @keypress="filterCharacter($event)"
                          maxlength="5"
                          class="schedule-time-input"
                          v-model="scheduleLeave"
                          @change="calculateWorkingTime"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-4 pr-0">
                <table class="w-100 schedule-time-tbl schedule-work">
                  <tbody>
                    <tr>
                      <td class="table-col">作業時間</td>
                      <td class="table-col">
                        <input
                          class="schedule-time-input"
                          type="number"
                          v-model="scheduleWork"
                          readonly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row m-0 text-danger"
          v-if="isTimeFormatError"
          style="padding-left: 15px; font-size: 12px"
        >
          <span>「hh:mm」型に日付を入力してください</span>
        </div>
      </div>
      <div class="row m-0 mt-3 justify-content-end">
        <button class="regist-button">登録</button>
      </div>
    </form>

    <confirm-modal
      :show="confirmModal.show"
      :title="confirmModal.title"
      :message="confirmModal.message"
      @onSubmit="onSubmitRequest"
      @onCancel="onCancelModal"
    />
  </div>
</template>

<script>
import jsCookie from "js-cookie";
import DatePicker from "vuejs-datepicker";
import { mapGetters } from "vuex";
import confirmModal from "../../components/ConfirmModal.vue";
import axios from "axios";

export default {
  components: { DatePicker, confirmModal },

  computed: {
    ...mapGetters({
      confirmModal: "delivery/confirmModal",
    }),
  },

  mounted() {
    setTimeout(() => this.onCancelModal(), 0.0001);
    this.getPullDownData();
  },

  data() {
    return {
      deliveryNumber: null,
      inChargePerson: null,
      deliveryDate: null,
      area: null,
      deliveryPointName: null,
      customerPhoneNumber: null,
      deliveryAddress: null,
      scheduleArrival: null,
      scheduleWork: null,
      scheduleLeave: null,
      token: jsCookie.get("token").split("|"),
      closeRegisterMsg: "編集中の内容が破棄されますがよろしいですか。",
      registDeliveryMsg: "この内容で登録します。よろしいですか。",
      displayMsg: "",
      inChargePersonList: [],
      areaList: [],
      isTimeFormatError: false,
    };
  },
  methods: {
    // get data area and in charge person (SW-0506)
    async getPullDownData() {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      const { data } = await axios.get(this.$store.state.serverUrl + "delivery/pulldown/v1", {
        params: {
          tenant_id: jsCookie.get("tenantId"),
        },
      });

      this.inChargePersonList = [];
      data.result.users?.forEach((element) => {
        const subUserList = {
          key: element.user_id,
          value: element.first_name + element.last_name,
        };

        this.inChargePersonList.push(subUserList);
      });

      data.result.areas?.forEach((element) => {
        const subAreaList = {
          key: element.area_id,
          value: element.area_name,
        };

        this.areaList.push(subAreaList);
      });
    },

    //auto enter ":" when user enter 2 character on input time
    filterCharacter(e) {
      if (e.target.value.length === 2) {
        e.target.value = e.target.value + ":";
      }
    },

    //auto enter "-" when user enter 3 or 8 character on input customer phone number
    filterPhoneNumberCharacter(e) {
      if (e.target.value.length === 3 || e.target.value.length === 8) {
        e.target.value = e.target.value + "-";
      }
    },

    //when click button 'x' or click outside register modal
    closeRegister() {
      const payload = {
        show: true,
        message: this.closeRegisterMsg,
      };
      this.displayMsg = this.closeRegisterMsg;
      this.$store.dispatch("delivery/showConfirmModal", payload);
    },

    // when click register button
    registDelivery() {
      if (
        this.deliveryNumber &&
        this.area &&
        this.deliveryPointName &&
        this.customerPhoneNumber &&
        this.deliveryAddress
      ) {
        const payload = {
          show: true,
          message: this.registDeliveryMsg,
        };
        this.displayMsg = this.registDeliveryMsg;
        this.$store.dispatch("delivery/showConfirmModal", payload);
      }
    },

    // when click button 'はい' on confirm modal after click register button
    onRequestRegist() {
      const timeRegex = /^([0-9][0-9]):[0-5][0-9]$/;
      if (
        (this.scheduleArrival && !timeRegex.test(this.scheduleArrival)) ||
        (this.scheduleLeave && !timeRegex.test(this.scheduleLeave))
      ) {
        return (this.isTimeFormatError = true);
      }
      var newData = {
        delivery_number: this.deliveryNumber,
        area_id: this.area,
        user_id: this.inChargePerson,
        date: this.deliveryDate,
        delivery_point_name: this.deliveryPointName,
        deliver_status: "0",
        customer_phone_number: this.customerPhoneNumber,
        address: this.deliveryAddress,
        schedule_arrive_time: this.scheduleArrival,
        schedule_work_time: (this.scheduleWork * 60).toFixed(0),
        schedule_leave_time: this.scheduleLeave,
      };

      let deliveryNumber = [];
      deliveryNumber.push(newData);

      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      let that = this;
      axios
        .post(this.$store.state.serverUrl + "delivery/v1", {
          tenant_id: Number(jsCookie.get("tenantId")),
          delivery_number: deliveryNumber,
        })
        .then(function (response) {
          if (response.status === 200) {
            that.$emit("onRegister");
          }
        })
        .catch((e) => {
          alert(e);
        });
    },

    // when click button 'はい' on confirm modal when click 'x' button
    onCloseRegist() {
      this.$emit("onCloseRegister");
    },

    // when click button 'はい' on confirm modal
    onSubmitRequest() {
      this.onCancelModal();
      if (this.displayMsg === this.registDeliveryMsg) {
        this.onRequestRegist();
      } else {
        this.onCloseRegist();
      }
    },

    // when click button 'いいえ' on confirm modal
    onCancelModal() {
      const payload = {
        show: false,
      };
      this.$store.dispatch("delivery/showConfirmModal", payload);
    },

    // when click calendar button in 配送日 input
    selectDeliveryDate() {
      this.$refs.deliveryDate.showCalendar();
    },

    // auto calculate schedule working time when enter data on schedule start and end time
    calculateWorkingTime() {
      let start, end;
      if (this.scheduleArrival) {
        const startHours = Number(this.scheduleArrival.split(':')[0]);
        const startMinutes = Number(this.scheduleArrival.split(':')[1]);

        start = startHours * 60 + startMinutes;
      }
      if (this.scheduleLeave) {
        const endHours = Number(this.scheduleLeave.split(':')[0]);
        const endMinutes = Number(this.scheduleLeave.split(':')[1]);

        end = endHours * 60 + endMinutes;
      }

      if (start && end) {
        if (start >= end) {
          return;
        }
        this.scheduleWork = ((end - start) / 60).toFixed(2);
      } else {
        this.scheduleWork = null;
      }
    },
  },
};
</script>

<style scoped>
.add-delivery-plan {
  position: absolute;
  z-index: 1;
  left: 28%;
  top: 9%;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 25%);
  border-radius: 10px;
  width: 40%;
}

.regist-body {
  overflow-y: auto;
}

.required:after {
  content: " *";
  color: red;
}

table {
  border-collapse: separate !important;
  border-spacing: 0;
}
.schedule-time-tbl td:nth-child(odd) {
  background: #d7f4fa;
  padding-left: 10px;
}
.schedule-work td:nth-child(odd) {
  background: #d9d9d9;
}
.schedule-time-tbl {
  border: 1px solid #b6b1b1;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.schedule-time-tbl td {
  border-left: 1px solid #b6b1b1;
  text-align: left;
  width: 109px;
  height: 31.48px;
}
.schedule-time-tbl td:first-child {
  border-left: none;
}
.schedule-time-tbl tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 5px;
  -webkit-border-radius: 0 0 0 5px;
  border-radius: 0 0 0 5px;
}
.schedule-time-tbl tr:last-child td:last-child {
  -moz-border-radius: 0 0 5px 0;
  -webkit-border-radius: 0 0 5px 0;
  border-radius: 0 0 5px 0;
}
.schedule-time-input {
  border: none;
  width: 100%;
  padding-left: 10px;
}
.schedule-time-input:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.close-btn {
  right: 0;
  border: none;
  background: transparent;
}
.bi-x {
  width: 35px;
  height: 35px;
}

.info-input {
  border: none;
  border-bottom: 1px solid #b6b1b1;
  padding-left: 10px;
}

.dropdown-data {
  width: 100%;
  padding-left: 10px;
  border: none;
  border-bottom: 1px solid #b6b1b1;
}

.dropdown-data:focus,
.vdp-datepicker >>> input:focus,
.info-input:focus {
  outline: none;
}

.info-input::placeholder {
  color: #b6b1b1;
}

.vdp-datepicker >>> input {
  border: none;
  width: 100%;
}

.regist-button {
  color: #ffffff;
  font-size: 16px;
  width: 115px;
  height: 32px;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
