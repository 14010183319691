<template>
  <div class="px-4" style="font-family: 'Roboto'; color: #000000">
    <div
      class="row m-0 flex-row align-items-center float-right"
      v-if="selectTab === 0 || selectTab === 2"
    >
      <button class="filter-btn" @click="searchData"></button>
      <i class="search-icon"></i>
      <input type="search" v-model="keySearch" class="search-input" @keyup.enter="searchData" />
      <paginate
        v-model="currentPage"
        :page-count="pageCount"
        :margin-pages="0"
        :page-range="0"
        :first-last-button="false"
        :clickHandler="handlePageSelected"
      ></paginate>

      <nav v-if="pageCount === 0">
        <ul class="pagination mb-0">
          <li class="page-item disabled">
            <button tabindex="-1" class="page-link">〈</button>
          </li>
          <li class="page-item disabled">
            <button tabindex="0" class="page-link">〉</button>
          </li>
        </ul>
      </nav>

      <span class="recordNumber" v-if="!isTotalData">
        {{ displayDataNumberFrom }} - {{ displayDataNumberTo }} / {{ totalDelivery }} 件</span
      >
      <span class="recordNumber" v-else> {{ displayDataNumberTo }} / {{ totalDelivery }} 件</span>
    </div>
    <div class="row m-0 w-100" :style="setDailyTabStyle()">
      <button
        class="p-2 add-delivery-btn d-flex justify-content-center align-items-center text-white"
        @click="
          isRegisterDelivery = true;
          isRemoveDelivery = false;
        "
      >
        <b-iconstack font-scale="1.5" rotate="0">
          <b-icon stacked icon="calendar4" shift-h="0"></b-icon>
          <b-icon stacked icon="plus" shift-h="14"></b-icon>
        </b-iconstack>
        <span class="pl-4">配送計画を登録する</span>
      </button>
      <button
        class="p-2 ml-3 remove-delivery-btn d-flex justify-content-center align-items-center"
        :disabled="!isRemoved"
        @click="onRemoveDelivery"
      >
        <b-iconstack font-scale="1.5" rotate="0">
          <b-icon stacked icon="calendar4" shift-h="0"></b-icon>
          <b-icon stacked icon="dash" shift-h="14"></b-icon>
        </b-iconstack>
        <span class="pl-4">配送計画を削除する</span>
      </button>
    </div>
    <div class="row m-0 w-100 mt-2">
      <div style="width: -webkit-fill-available">
        <b-card no-body>
          <b-tabs
            active-nav-item-class="font-weight-bold active-nav"
            content-class="mt-3"
            v-model="selectTab"
          >
            <b-tab title="全配送計画" active @click.prevent="getAllDeliveryData(1)">
              <full-delivery
                ref="fullDeliveryPlan"
                @enableRemoveButton="onEnableRemoveBtn"
                :isOpenRemove="isRemoveDelivery"
                @isRemoved="isRemoveDelivery = false"
                @paginate="onPaginate"
                :page="currentPage"
                :search="keySearch"
                :dataPerPage="dataPerPage"
              />
            </b-tab>
            <b-tab title="日別配送計画" @click.prevent="onClickDailyDeliveryTab">
              <daily-delivery
                ref="dailyDelivery"
                :selectedDate="selectedDate"
                @enableRemoveButton="onEnableRemoveBtn"
                :isOpenRemove="isRemoveDelivery"
                @isRemoved="isRemoveDelivery = false"
              />
            </b-tab>
            <b-tab title="計画未設定" @click="getUnsetDeliveryData(1)">
              <plan-unset
                ref="unsetDelivery"
                @enableRemoveButton="onEnableRemoveBtn"
                :isOpenRemove="isRemoveDelivery"
                @isRemoved="isRemoveDelivery = false"
                @paginate="onPaginate"
                :page="currentPage"
                :search="keySearch"
                :dataPerPage="dataPerPage"
              />
            </b-tab>

            <template #tabs-end v-if="selectTab === 1">
              <div class="d-flex justify-content-end align-items-center" style="flex: 1">
                <nav>
                  <ul class="date-select pagination mb-0 align-items-center">
                    <li class="page-item">
                      <button tabindex="-1" class="page-link" @click="selectPreDate">〈</button>
                    </li>
                    <li class="page-item" style="width: 120px">
                      <button
                        class="calendar-btn input-group d-flex align-items-center border"
                        @click="selectDeliveryDate"
                      >
                        <span>{{ selectedDate }}</span>
                        <b-icon-calendar4 style="position: absolute; right: 5" />
                      </button>

                      <date-picker
                        class="border-0"
                        format="yyyy/M/d"
                        ref="deliveryDate"
                        v-model="selectedDate"
                      ></date-picker>
                    </li>
                    <li class="page-item">
                      <button tabindex="0" class="page-link" @click="selectAfterDate">〉</button>
                    </li>
                  </ul>
                </nav>
              </div>
            </template>
          </b-tabs>
        </b-card>
      </div>

      <add-delivery
        v-if="isRegisterDelivery"
        @onCloseRegister="closeRegisterTab"
        @onRegister="onRegister"
      />
    </div>
  </div>
</template>

<script>
import Paginate from "../components/Paginate.vue";
import FullDelivery from "./DeliveryManage/FullDeliveryPlan.vue";
import AddDelivery from "./DeliveryManage/AddDeliveryPlan.vue";
import DailyDelivery from "./DeliveryManage/DailyDeliveryPlan.vue";
import PlanUnset from "./DeliveryManage/UnsetDataDeliveryPlan.vue";
import jsCookie from "js-cookie";
import DatePicker from "vuejs-datepicker";

export default {
  components: {
    Paginate,
    FullDelivery,
    AddDelivery,
    DailyDelivery,
    PlanUnset,
    DatePicker,
  },

  data() {
    return {
      isRemoved: false,
      isAllChecked: false,
      isRegisterDelivery: false,
      isRemoveDelivery: false,
      token: jsCookie.get("token").split("|"),
      totalDelivery: 0,
      //number of data on a page
      dataPerPage: 20,
      //number of displayed data
      displayDataNumberFrom: 1,
      displayDataNumberTo: 20,
      isTotalData: false,
      currentPage: 1,
      keySearch: null,
      pageCount: 1,
      selectTab: 0,
      selectedDate: new Date().toLocaleDateString("ja-JP"),
    };
  },

  methods: {
    //set class to add and remove group button
    setDailyTabStyle() {
      if (this.selectTab === 1) {
        return "padding-top: 2.3rem;";
      } else {
        return "";
      }
    },

    //when change display tab
    onChangeTab() {
      this.onEnableRemoveBtn(false);
      this.isRemoveDelivery = false;
      this.isRegisterDelivery = false;
    },

    //get data delivery plan
    getAllDeliveryData(page) {
      this.currentPage = page;
      this.$refs.fullDeliveryPlan.getDeliveryPlanList(page);
      this.onChangeTab();
      this.$refs.fullDeliveryPlan.closeEditTab();
    },

    //when change page
    handlePageSelected(page) {
      this.currentPage = page;
      if (this.selectTab === 0) {
        this.getAllDeliveryData(this.currentPage);
      } else if (this.selectTab === 2) {
        this.getUnsetDeliveryData(this.currentPage);
      }
    },

    //when click button filter or enter after have a key search
    searchData() {
      if (this.selectTab === 0) {
        this.getAllDeliveryData(1);
      } else if (this.selectTab === 2) {
        this.getUnsetDeliveryData(1);
      }
    },

    // execute data pagination
    onPaginate(data) {
      this.totalDelivery = data.totalDelivery;
      this.displayDataNumberFrom = data.displayDataNumberFrom;
      this.displayDataNumberTo = data.displayDataNumberTo;
      this.isTotalData = data.isTotalData;
      this.pageCount = data.pageCount;
    },

    // get delivery plan that not set data
    getUnsetDeliveryData(page) {
      this.currentPage = page;
      this.$refs.unsetDelivery.getUnsetDeliveryPlan(page);
      this.onChangeTab();
      this.$refs.unsetDelivery.closeEditTab();
    },

    // close register tab
    closeRegisterTab() {
      this.isRegisterDelivery = false;
    },

    // register new delivery plan
    onRegister() {
      this.closeRegisterTab();
      this.getAllDeliveryData(this.currentPage);

      // return to 全配送計画 tab
      this.selectTab = 0;
    },

    // enable remove delivery plan button
    onEnableRemoveBtn(newVal) {
      this.isRemoved = newVal;
    },

    // remove delivery plan
    onRemoveDelivery() {
      this.isRemoveDelivery = true;
      if (this.selectTab === 0) {
        this.$refs.fullDeliveryPlan.openRemoveTab(this.selectTab);
      } else if (this.selectTab === 1) {
        this.$refs.dailyDelivery.openRemoveTab(this.selectTab);
      } else {
        this.$refs.unsetDelivery.openRemoveTab(this.selectTab);
      }
      this.isRegisterDelivery = false;
    },

    // only display calendar when click on 日別配送計画 tab, call function to get data on daily delivery tab
    onClickDailyDeliveryTab() {
      this.$refs.dailyDelivery.getDailyDeliveryTabData(this.selectedDate);
      this.onChangeTab();
      this.$refs.dailyDelivery.closeEdit();
    },

    // select previous date on tab 日別配送計画
    selectPreDate() {
      var date = new Date(this.selectedDate);
      const preDate = date.setDate(date.getDate() - 1);
      this.selectedDate = new Date(preDate).toLocaleDateString("ja-JP");
      this.$refs.dailyDelivery.getPrevDate();
    },

    // select next date on tab 日別配送計画
    selectAfterDate() {
      var date = new Date(this.selectedDate);
      const nextDate = date.setDate(date.getDate() + 1);
      this.selectedDate = new Date(nextDate).toLocaleDateString("ja-JP");
      this.$refs.dailyDelivery.getNextDate();
    },

    // when click calendar button in 配送日 input
    selectDeliveryDate() {
      this.$refs.deliveryDate.showCalendar();
    },
  },

  watch: {
    selectedDate: {
      handler(newVal) {
        this.selectedDate = new Date(newVal).toLocaleDateString("ja-JP");
        this.$refs.dailyDelivery.onChooseDate(this.selectedDate);
      },
    },
  },
};
</script>

<style scoped>
.filter-btn {
  background: url("../assets/filter-icon.png");
  width: 24px;
  height: 24px;
  border: none;
  margin-right: 25.83px;
}
.search-icon {
  background: url("../assets/search-icon.png");
  width: 22px;
  height: 21px;
  margin-right: -21px;
  z-index: 1;
}
.form-outline {
  border-bottom: 0.5px solid #b6b1b1;
}
.search-input {
  width: 206px;
  border: none;
  border-bottom: 0.5px solid #b6b1b1;
  border-radius: 0px;
  padding-left: 30px;
}
.search-input:focus {
  outline: none;
  box-shadow: none;
}
input.search-input[type="search"]::-webkit-search-decoration,
input.search-input[type="search"]::-webkit-search-cancel-button,
input.search-input[type="search"]::-webkit-search-results-button,
input.search-input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.recordNumber {
  font-weight: 400;
  font-size: 12px;
}
.add-delivery-btn {
  width: 200px;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
  font-size: 14px;
}
.remove-delivery-btn {
  color: #000000;
  width: 200px;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 5px;
  font-size: 14px;
}
.remove-delivery-btn:disabled {
  color: #b6b1b1;
  width: 200px;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
  font-size: 14px;
}

.card {
  border: none;
  border-left: 1px solid #b6b1b1;
}
.tabs >>> .nav-item {
  width: 15%;
  font-size: 16px;
  border-right: 0.5px solid #b6b1b1;
  border-top: 0.5px solid #b6b1b1;
}
.tabs >>> .active-nav {
  border: none;
  border-bottom: 7px solid #8bdff9 !important;
}
.tabs >>> .nav-link {
  color: #000000;
  text-align: center;
  border-radius: 0;
  font-size: 16px;
}
.tabs >>> .nav-link:hover {
  border: none;
}
.tabs >>> .mt-3 {
  margin: 0;
  padding: 0;
}
.page-item.disabled .page-link {
  color: #b6b1b1;
  border: none;
}

.date-select .page-item .page-link,
.calendar-btn {
  border: none;
  background: none;
  color: #000000;
}

.date-select .page-item .page-link:focus {
  box-shadow: none;
}

.vdp-datepicker >>> input {
  display: none;
}

.vdp-datepicker >>> .vdp-datepicker__calendar {
  right: -35%;
  top: 10px;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
