<template>
  <div class="remove-delivery p-4">
    <div class="row m-0 font-weight-bold" style="font-size: 20px; color: #000000">
      <p>配送計画を削除する</p>
    </div>
    <p v-if="deliveryNumber">
      以下の{{ deliveryNumber.length }}件の配送計画を削除します。本当によろしいですか？
    </p>
    <div style="height: 120px; overflow-y: auto">
      <div>
        <ul v-for="(item, idx) in deliveryNumber" :key="idx" style="height: ">
          <li v-if="index === 0 || index === 2">
            配送番号: {{ item.delivery_number }}&nbsp;&nbsp;{{ item.delivery_point_name }}
          </li>
          <!-- 7/1(火)1:00~5:00 名古屋産業センター 担当：鈴木太郎 -->
          <li v-else-if="index === 1">
            {{ formatDate(item.delivery_date) }}{{ item.schedule_arrival_time }}~{{
              item.schedule_leave_time
            }}&nbsp;&nbsp;&nbsp;{{ item.delivery_point_name }}&nbsp;&nbsp;&nbsp;担当：{{
              item.in_charge_person
            }}
          </li>
        </ul>
      </div>
    </div>
    <div class="row m-0 mt-4 button justify-content-end">
      <button class="btn cancel-btn" @click="cancelDelete">キャンセル</button>
      <button class="ml-4 btn delete-btn" @click="deleteDeliveryPlan">削除</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsCookie from "js-cookie";

export default {
  props: {
    deliveryNumber: Array,
    tabindex: Number,
  },

  data() {
    return {
      token: jsCookie.get("token").split("|"),
      index: this.tabindex,
    };
  },

  methods: {
    // cancel delete
    cancelDelete() {
      this.$emit("onCancelDelete");
    },

    // delete delivery plan
    deleteDeliveryPlan() {
      const removeList = this.deliveryNumber.map((x) => {
        return {
          delivery_id: x.delivery_id,
          delivery_route_id: x.delivery_route_id,
        };
      });
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      let that = this;
      axios
        .delete(this.$store.state.serverUrl + "delivery/v1", {
          data: {
            delivery_id_number: removeList,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            that.$emit("onDelete", that.deliveryNumber);
          }
        })
        .catch((e) => {
          alert(e);
        });
    },

    //format date to display
    formatDate(value) {
      const date = new Date(value).getDate();
      const month = new Date(value).getMonth() + 1;
      let dayOfWeek;
      switch (new Date(value).getDay()) {
        case 1:
          dayOfWeek = "月";
          break;
        case 2:
          dayOfWeek = "火";
          break;
        case 3:
          dayOfWeek = "水";
          break;
        case 4:
          dayOfWeek = "木";
          break;
        case 5:
          dayOfWeek = "金";
          break;
        case 6:
          dayOfWeek = "土";
          break;
        default:
          dayOfWeek = "日";
      }
      const dayFormatted = month + "/" + date + "(" + dayOfWeek + ")";
      return dayFormatted;
    },
  },
};
</script>

<style scoped>
.remove-delivery {
  position: absolute;
  left: 30%;
  top: 23%;
  width: 42%;
  min-height: 209px;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #000000;
  z-index: 2;
}
.btn {
  width: 115px;
  min-height: 32px;
  border-radius: 5px;
  font-size: 16px;
}

.cancel-btn {
  color: #948c8c;
  background: #ffffff;
  border: 1px solid #c4c4c4;
}

.delete-btn {
  color: #ffffff;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
