import { render, staticRenderFns } from "./UnsetDataDeliveryPlan.vue?vue&type=template&id=42485b7d&scoped=true&"
import script from "./UnsetDataDeliveryPlan.vue?vue&type=script&lang=js&"
export * from "./UnsetDataDeliveryPlan.vue?vue&type=script&lang=js&"
import style0 from "./UnsetDataDeliveryPlan.vue?vue&type=style&index=0&id=42485b7d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42485b7d",
  null
  
)

export default component.exports