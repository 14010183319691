<template>
  <div class="unset-data-table" style="height: 73vh; overflow-y: auto">
    <b-table
      :items="items"
      :fields="visibleFields"
      class="border"
      style="color: #000000 !important"
    >
      <template slot="thead-top">
        <tr role="row" style="height: 49px">
          <th width="50px"></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th v-if="!isOpenEditDataTab"></th>
          <th v-if="!isOpenEditDataTab"></th>
          <th colspan="3" class="spanCol text-center" v-if="!isOpenEditDataTab">予定時刻</th>
        </tr>
      </template>
      <template v-slot:head(checkbox)>
        <input
          type="checkbox"
          class="table-checkbox"
          :checked="isAllChecked"
          @change="checkAllCheckbox"
        />
      </template>
      <template v-slot:cell(checkbox)="{ item }">
        <input type="checkbox" class="table-checkbox" v-model="item.isChecked" />
      </template>
      <template v-slot:cell(edit)="{ item }">
        <button class="btn edit-btn" @click="openEditData(item)"></button>
      </template>
      <template v-slot:cell(edit_tab)="{ item }">
        <edit-delivery-plan
          v-if="item.isOpenEditData"
          :data="item"
          @onEditData="onEditData"
          @closeEdit="closeEditTab"
        />
      </template>
    </b-table>
    <remove-delivery-plan
      v-if="isRemoveDelivery"
      :deliveryNumber="removedDeliveryList"
      :tabindex="tabIndex"
      @onCancelDelete="onCancelDelete"
      @onDelete="onDeleteDelivery"
    />
  </div>
</template>

<script>
import axios from "axios";
import editDeliveryPlan from "./EditDeliveryPlan.vue";
import removeDeliveryPlan from "./RemoveDeliveryPlan.vue";
import jsCookie from "js-cookie";

export default {
  components: { editDeliveryPlan, removeDeliveryPlan },

  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isRemoveDelivery() {
      return this.isOpenRemoveTab;
    },
  },

  props: {
    isOpenRemove: Boolean,
    page: null,
    search: null,
    dataPerPage: null,
  },

  data() {
    return {
      isAllChecked: false,
      fields: [
        { key: "checkbox", label: "", visible: true },
        { key: "edit", label: "編集", visible: true },
        { key: "delivery_number", label: "配送番号", visible: true },
        { key: "area", label: "エリア", visible: true },
        { key: "delivery_date", label: "配送日", visible: true },
        { key: "in_charge_person", label: "担当者", visible: true },
        { key: "delivery_point_name", label: "配送地点名", visible: true },
        { key: "customer_phone_number", label: "顧客電話番号", visible: true },
        { key: "delivery_address", label: "配送先住所", visible: true },
        { key: "schedule_arrival_time", label: "到着", visible: true },
        { key: "schedule_leave_time", label: "出発", visible: true },
        { key: "schedule_work_time", label: "作業時間", visible: true },
      ],
      items: [],
      isOpenEditDataTab: false,
      isOpenRemoveTab: this.isOpenRemove,
      removedDeliveryList: [],
      tabIndex: null,
      token: jsCookie.get("token").split("|"),
      totalData: null,
    };
  },

  methods: {
    // get data delivery plan that not have value on these column 配送日, 担当者, 到着予定時刻, 作業予定時間, 出発予定時刻
    async getUnsetDeliveryPlan(page) {
      this.items = [];
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      let that = this;
      axios
        .get(this.$store.state.serverUrl + "delivery/unset/v1", {
          params: {
            tenant_id: Number(jsCookie.get("tenantId")),
            key_search: this.search,
            offset: (page - 1) * this.dataPerPage,
            limit: this.dataPerPage,
          },
        })
        .then(function (response) {
          let unsetData = response.data.result.delivery_number;

          const totalData = response.data.result.total_delivery_count;
          that.totalData = totalData;
          const displayDataNumberTo =
            totalData < that.dataPerPage * page ? totalData : that.dataPerPage * page;

          const displayDataNumberFrom = page <= 1 ? 1 : that.dataPerPage * (page - 1) + 1;

          let paginateData = {
            totalDelivery: totalData,
            pageCount: Math.ceil(totalData / that.dataPerPage),
            displayDataNumberFrom: displayDataNumberFrom,
            displayDataNumberTo: displayDataNumberTo,
            isTotalData: that.dataPerPage * (page - 1) + 1 === totalData,
          };

          that.$emit("paginate", paginateData);

          unsetData?.forEach((element) => {
            const subItem = {
              delivery_id: element.delivery_id,
              area_id: element.area_id,
              area: element.area_name,
              delivery_number: element.delivery_number,
              delivery_route_id: element.route_id,
              delivery_date: that.isEmpty(element.date) ? "未設定" : element.date,
              in_charge_person:
                that.isEmpty(element.user_id) ? "未設定" : element.first_name + " " + element.last_name,
              user_id: element.user_id,
              delivery_point_name: element.destination,
              customer_phone_number: element.customer_phone_number,
              delivery_address: element.address,
              schedule_arrival_time:
                that.isEmpty(element.schedule_arrive_at) ? "未設定" : element.schedule_arrive_at,
              schedule_leave_time:
                that.isEmpty(element.schedule_depart_at) ? "未設定" : element.schedule_depart_at,
              schedule_work_time:
                that.isEmpty(element.schedule_operation_time)
                  ? "未設定"
                  : element.schedule_operation_time,
              isChecked: false,
              isOpenEditData: false,
            };

            that.items.push(subItem);
          });
        });
    },

    //check/uncheck all checkbox in table
    checkAllCheckbox() {
      const status = !this.isAllChecked;
      this.isAllChecked = status;
      const newItems = this.items.map((x) => {
        return {
          ...x,
          isChecked: status,
        };
      });
      this.items = [...newItems];
    },

    //open edit delivery information tab
    openEditData(item) {
      const invisibleFields = [
        "customer_phone_number",
        "delivery_address",
        "schedule_arrival_time",
        "schedule_leave_time",
        "schedule_work_time",
        "in_charge_person",
      ];

      const oldDeliveryData = this.items.map((x) => {
        return { ...x, isChecked: false, isOpenEditData: false };
      });
      const index = oldDeliveryData.findIndex(
        (x) => x.delivery_id === item.delivery_id && x.delivery_route_id === item.delivery_route_id
      );
      oldDeliveryData[index].isOpenEditData = true;
      oldDeliveryData[index].isChecked = true;
      this.isOpenEditDataTab = true;

      this.items = [...oldDeliveryData];

      // format visible field on open edit tab
      if (this.isOpenEditDataTab) {
        // disable label on invisible array
        this.fields.forEach((element) => {
          if (invisibleFields.some((data) => data === element.key)) {
            element.visible = false;
          }
        });

        if (!this.fields.some((x) => x.key === "edit_tab")) {
          // add a new visible field
          this.fields.push({
            key: "edit_tab",
            label: null,
            visible: true,
            thClass: "additional-col",
          });
        }
      }
    },

    // after edit data success
    onEditData() {
      this.getUnsetDeliveryPlan(this.page);
      this.closeEditTab();
    },

    // when click 'X' button on edit tab
    closeEditTab() {
      this.items.map((x) => {
        return { ...x, isChecked: false, isOpenEditData: false };
      });

      // reformat table
      const oldFields = this.fields.map((x) => {
        return { ...x, visible: true };
      });

      const idx = oldFields.findIndex((x) => x.key === "edit_tab");

      // remove new field added above
      if(idx >= 0) oldFields.splice(idx, 1);

      this.fields = [...oldFields];
      this.isOpenEditDataTab = false;
    },

    // open remove delivery plan modal
    openRemoveTab(tabIdx) {
      const deliveryList = this.items.filter((x) => x.isChecked);
      this.removedDeliveryList = [...deliveryList];
      this.tabIndex = tabIdx;
    },

    // when click button キャンセル on modal delete
    onCancelDelete() {
      this.isOpenRemoveTab = false;
      this.$emit("isRemoved");
    },

    // after delete delivery plan success
    onDeleteDelivery() {
      if (this.totalData < this.dataPerPage * this.page) {
        // get data previous page when data on this page is empty
        this.getUnsetDeliveryPlan(this.page - 1);
      } else {
        this.getUnsetDeliveryPlan(this.page);
      }
      this.isOpenRemoveTab = false;
      this.closeEditTab();
      this.$emit("enableRemoveButton", false);
    },

    // check value is null, "" or undefined
    isEmpty(value)
    {
      return value === null || value === "" || value === undefined
    }
  },

  watch: {
    items: {
      handler(newValue) {
        const isRemoved = newValue.some((x) => x.isChecked);
        this.$emit("enableRemoveButton", isRemoved);
        this.isAllChecked = !newValue.some((x) => !x.isChecked);
      },
      deep: true,
    },

    isOpenRemove: {
      handler(newVal) {
        this.isOpenRemoveTab = newVal;
      },
    },
  },
};
</script>

<style scoped>
.b-table >>> thead {
  background: rgba(0, 0, 0, 0.03);
}
.b-table >>> tbody {
  font-size: 14px !important;
}
.b-table >>> th {
  font-weight: normal !important;
  vertical-align: top !important;
  border-top: none !important;
  border-bottom: none !important;
}
.table-checkbox {
  min-width: 19px;
  min-height: 19px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
}
.edit-btn {
  width: 24px;
  height: 24px;
  background: url("../../assets/edit-icon.png");
}
.b-table >>> .additional-col {
  width: 40.7%;
}
.b-table >>> .additional-col div {
  display: none;
}
</style>
