<template>
  <div class="daily-delivery-plan">
    <calendar class="daily-delivery" ref="calendar" :options="calendarOptions" />
    <edit-tab
      style="top: 18%"
      v-if="isOpenEdit"
      @closeEdit="closeEdit"
      @onEditData="onEdit"
      :data="editData"
    />
    <remove-tab
      v-if="isRemoveDelivery"
      :deliveryNumber="removedDeliveryNumber"
      :tabindex="tabIndex"
      @onCancelDelete="onCancelDelete"
      @onDelete="onDeleteDelivery"
    />
  </div>
</template>

<script>
import axios from "axios";
import jsCookie from "js-cookie";
import "@fullcalendar/core/vdom";
import Calendar from "@fullcalendar/vue";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import EditTab from "./EditDeliveryPlan.vue";
import RemoveTab from "./RemoveDeliveryPlan.vue";

export default {
  components: { Calendar, EditTab, RemoveTab },

  props: {
    selectedDate: String,
    isOpenRemove: Boolean,
  },

  computed: {
    isRemoveDelivery() {
      return this.isOpenRemoveTab;
    },
  },

  mounted() {
    this.afterDate();
    this.displayNextDate();
  },

  data() {
    return {
      tabIndex: null,
      date: this.selectedDate,
      nextDate: null,
      calendarOptions: {
        timeZone: "Asia/Tokyo",
        locale: "ja",
        plugins: [resourceTimelinePlugin],
        initialView: "resourceTimelineDay",
        headerToolbar: { start: "title", end: "" },
        titleFormat: {
          month: "numeric",
          day: "numeric",
          weekday: "short",
        },
        aspectRatio: 1.5,
        resourceOrder: "employee_number",
        resourceAreaWidth: "13%",
        resourceAreaColumns: [
          {
            field: "employee_number",
            headerContent: "社員番号",
          },
          {
            field: "full_name",
            headerContent: "名前",
          },
        ],
        resources: null,
        events: null,
        // add other row to event title
        eventDidMount: function (info) {
          let selector = info.el.querySelector(".fc-event-title");
          if (selector) {
            selector.innerHTML = info.event.title + `<br>${info.event.extendedProps.description}`;
          }
        },
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
        },
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
        },
        eventClick: this.handleEventClick,
      },

      token: jsCookie.get("token").split("|"),
      isOpenEdit: false,
      dailyData: null,
      editData: null,
      isOpenRemoveTab: this.isOpenRemove,
      removedDeliveryNumber: null,
    };
  },

  methods: {
    // when click button "<" on header calendar
    getPrevDate() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.prev();
    },

    // when click button ">" on header calendar
    getNextDate() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.next();
    },

    // get data daily delivery plan (that have value on these column 配送日, 担当者, 到着予定時刻, 作業予定時間, 出発予定時刻)
    async getDailyDeliveryTabData(date) {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      // all data on chosen date
      const { data } = await axios.get(this.$store.state.serverUrl + "delivery/v1", {
        params: {
          tenant_id: Number(jsCookie.get("tenantId")),
          date: date,
        },
      });

      // filter data have value 配送日, 担当者, 到着予定時刻, 作業予定時間, 出発予定時刻
      let subData = data.result.delivery_number?.filter(
        (x) =>
          x.date !== null &&
          x.user_id !== null &&
          x.schedule_arrive_at !== null &&
          x.schedule_operation_time !== null &&
          x.schedule_depart_at !== null
      );

      this.dailyData = subData;

      let resource = [];
      let events = [];

      // create an array contain 14 empty element of resource
      for (let i = 0; i <= 13; i++) {
        resource.push({});
      }

      // format date to yyyy-mm-dd
      let dateFormat = new Date(date).toLocaleDateString("en-CA");
      subData?.forEach((element, idx) => {
        // get calendar resource value
        const subResource = {
          id: element.user_id,
          full_name: element.first_name + " " + element.last_name,
          employee_number: element.employee_number,
        };

        // get calendar event
        const subEvent = {
          id: String(element.delivery_id + "_" + element.route_id),
          resourceId: element.user_id,
          title: `${element.schedule_arrive_at}~${element.schedule_depart_at}`,
          start: `${dateFormat}T${element.schedule_arrive_at}:00`,
          end: `${dateFormat}T${element.schedule_depart_at}:00`,
          description: element.destination,
        };

        if (resource.length >= subData.length) {
          resource[idx] = subResource;
        } else {
          resource.push(subResource);
        }

        events.push(subEvent);
      });

      this.calendarOptions.resources = resource;
      this.calendarOptions.events = events;
    },

    // when choose a date on header calendar
    onChooseDate(newDate) {
      this.$refs.calendar
        .getApi()
        .changeView("resourceTimelineDay", new Date(newDate).toLocaleDateString("en-CA"));
    },

    // get the day after current day
    afterDate() {
      var currentDate = new Date(this.date);
      const newDate = currentDate.setDate(currentDate.getDate() + 1);
      const date = new Date(newDate).getDate();
      const month = new Date(newDate).getMonth() + 1;

      let dayOfWeek;
      switch (new Date(newDate).getDay()) {
        case 1:
          dayOfWeek = "月";
          break;
        case 2:
          dayOfWeek = "火";
          break;
        case 3:
          dayOfWeek = "水";
          break;
        case 4:
          dayOfWeek = "木";
          break;
        case 5:
          dayOfWeek = "金";
          break;
        case 6:
          dayOfWeek = "土";
          break;
        default:
          dayOfWeek = "日";
      }

      this.nextDate = month + "/" + date + "(" + dayOfWeek + ")";
    },

    // display after date on calendar
    displayNextDate() {
      let nextDateHeader = document.querySelector(".fc-toolbar-chunk:nth-child(3)");
      nextDateHeader.innerHTML = `<h2 class='fc-toolbar-title' id='fc-dom-2'>${this.nextDate}</h2>`;
    },

    // execute when click on calendar event
    handleEventClick(clickInfo) {
      // enable remove button
      this.$emit("enableRemoveButton", true);

      // get event id
      let eventId = clickInfo.event.id;

      // get delivery id and route id in event id
      let deliveryId = Number(eventId.split("_")[0]);
      let routeId = Number(eventId.split("_")[1]);

      let data = this.dailyData.filter(
        (x) => x.delivery_id === deliveryId && x.route_id === routeId
      );

      this.editData = {
        delivery_id: data[0].delivery_id,
        area_id: data[0].area_id,
        area: data[0].area_name,
        delivery_number: data[0].delivery_number,
        delivery_route_id: data[0].route_id,
        delivery_date: data[0].date === null ? "未設定" : data[0].date,
        user_id: data[0].user_id,
        in_charge_person: data[0].first_name + data[0].last_name,
        delivery_status: data[0].deliver_status === "0" ? "未完了" : "完了",
        delivery_point_name: data[0].destination,
        customer_phone_number: data[0].customer_phone_number,
        delivery_address: data[0].address,
        schedule_arrival_time:
          data[0].schedule_arrive_at === null ? "未設定" : data[0].schedule_arrive_at,
        schedule_leave_time:
          data[0].schedule_depart_at === null ? "未設定" : data[0].schedule_depart_at,
        schedule_work_time:
          data[0].schedule_operation_time === null ? "未設定" : data[0].schedule_operation_time,
      };

      // open edit tab
      this.isOpenEdit = true;
    },

    // close edit tab
    closeEdit() {
      this.isOpenEdit = false;
      if (!this.isOpenRemoveTab) {
        this.$emit("enableRemoveButton", false);
      }
    },

    // after save edit success
    onEdit() {
      this.closeEdit();
      this.getDailyDeliveryTabData(this.date);
    },

    // open remove delivery plan modal
    openRemoveTab(tabIdx) {
      const deliveryList = [];
      deliveryList.push(this.editData);
      this.tabIndex = tabIdx;
      this.removedDeliveryNumber = [...deliveryList];
    },

    // when click button キャンセル on modal delete
    onCancelDelete() {
      this.isOpenRemoveTab = false;
      this.$emit("isRemoved");
      if (!this.isOpenEdit) {
        this.$emit("enableRemoveButton", false);
      }
    },

    // after delete delivery plan success
    onDeleteDelivery() {
      this.onCancelDelete();
      this.closeEdit();
      this.getDailyDeliveryTabData(this.date);
    },
  },

  watch: {
    selectedDate: {
      handler(newVal) {
        this.date = newVal;
        this.afterDate();
        this.displayNextDate();
        this.getDailyDeliveryTabData(this.date);
      },
    },

    isOpenRemove: {
      handler(newVal) {
        this.isOpenRemoveTab = newVal;
      },
    },
  },
};
</script>

<style scoped>
/* disappear license message */
.daily-delivery >>> .fc-license-message {
  display: none;
}

/* setting date header font size */
.daily-delivery >>> .fc-toolbar-title {
  font-size: 16px;
}

/* setting header */
.daily-delivery >>> .fc-header-toolbar,
.daily-delivery >>> .fc-scrollgrid-section-header {
  padding-bottom: 1.5em;
  margin-bottom: 0 !important;
  border-top: 1px solid #b6b1b1;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.03);
}

/* setting table height */
.daily-delivery >>> .fc-view-harness-active {
  height: 68vh !important;
}

/* setting time header border */
.daily-delivery >>> .fc-scrollgrid {
  border-top: none;
}

/* setting table border */
.daily-delivery >>> td {
  border: none !important;
  border-bottom: 0.5px solid #b6b1b1 !important;
}

.daily-delivery >>> th {
  border: none;
}

/* setting day text position */
.daily-delivery >>> .fc-toolbar-chunk {
  margin-left: 13%;
}

/* setting header label */
.daily-delivery >>> .fc-datagrid-cell-main,
.daily-delivery >>> .fc-timeline-slot-label {
  font-weight: normal;
  font-size: 14px;
}

/* setting header label */
.daily-delivery >>> .fc-timeline-slot-cushion {
  color: #000000;
}

/* setting divider */
.daily-delivery >>> .fc-resource-timeline-divider {
  width: 1px !important;
  background: #b6b1b1 !important;
}

/* setting event display */
.daily-delivery >>> .fc-timeline-event-harness {
  top: 10% !important;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
  /* height: 100%; */
}

/* setting padding for name and employee*/
.daily-delivery >>> .fc-datagrid-cell-cushion {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
}

/* width for scroll-y and height for scroll-x */
.daily-delivery >>> ::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
.daily-delivery >>> ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

.daily-delivery >>> ::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
.daily-delivery >>> ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
